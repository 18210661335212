import * as Sentry from "@sentry/vue";

function getSentryIntegrations() {
    // don't load on server
    if (!process.client) return [];

    const router = useRouter();
    const browserTracing = new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    });
    const replay = new Sentry.Replay();

    return [browserTracing, replay];
}



export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    async setup(nuxtApp) {
        const vueApp = nuxtApp.vueApp;
        const config = useRuntimeConfig();
        
        if (config.public.APP_ENVIRONMENT === 'production') {
            
            if (!config.public.SENTRY_DSN_PUBLIC) {
                console.warn('SENTRY_DSN_PUBLIC is not set. Sentry will not initialize.');
                return;
            }
            
            Sentry.init({
                app: vueApp,
                dsn: config.public.SENTRY_DSN_PUBLIC as string ?? null,
                integrations: getSentryIntegrations(),
                tracesSampleRate: 1.0,
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
                environment: config.public.APP_ENVIRONMENT as string ?? null,
            });
        }
    }
});
