
import { rudderstack } from "vue-rudderstack";


const WRITE_KEY = import.meta.env.VITE_APP_RUDDER_WRITE_KEY || "123456";
const DATA_PLANE_URL =
    import.meta.env.VITE_APP_RUDDER_DATA_PLANE_URL ||
    "https://www.apexnetwork.co";
export default defineNuxtPlugin((nuxtApp) => {

    nuxtApp.vueApp.use(rudderstack, {
        writeKey: WRITE_KEY,
        dataPlaneUrl: DATA_PLANE_URL,
    })

});