import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin(() => {
  const router = useRouter()
  
  router.options.scrollBehavior = (to, from, savedPosition) => {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    return { left: 0, top: 0 }
  }
})