<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
<script setup>
import { gsap } from "gsap";

// Rudderstack
const WRITE_KEY = import.meta.env.VITE_APP_RUDDER_WRITE_KEY || "123456";
const DATA_PLANE_URL =
    import.meta.env.VITE_APP_RUDDER_DATA_PLANE_URL ||
    "https://www.apexnetwork.co";

// TODO: Add env variables for Rudderstack and pass them to the script below

useHead({
  title: 'Apex',
  htmlAttrs: {
    lang: 'en'
  },
  charset: 'utf-8',
  meta: [
    { name: 'description', content: 'apex' }
  ],
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: '/favicon.ico?v2',
    },
  ],
})

const animate = () => {
  gsap.to("#__nuxt", {
    visibility: "visible",
    duration: .5,
    ease: "sine",
  })
}

onMounted(() => {
  animate()
})
</script>
