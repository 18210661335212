import { default as about_45usWjawxlkv4gMeta } from "/app/pages/about-us.vue?macro=true";
import { default as blogsvd3tCE4tOqMeta } from "/app/pages/blogs.vue?macro=true";
import { default as animationsyTNd1827OMMeta } from "/app/pages/business/animations.ts?macro=true";
import { default as book_45a_45demockNhao9zG3Meta } from "/app/pages/business/book-a-demo.vue?macro=true";
import { default as indexqWs6x4EYp0Meta } from "/app/pages/business/index.vue?macro=true";
import { default as schemaD7mzE6zVGcMeta } from "/app/pages/business/schema.ts?macro=true";
import { default as utils9hUqlRtnqkMeta } from "/app/pages/business/utils.ts?macro=true";
import { default as contact_45uszp1HYnA7MsMeta } from "/app/pages/contact-us.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as privacy_45noticegeuSyXysWNMeta } from "/app/pages/privacy-notice.vue?macro=true";
import { default as rates_45oldCKSdZcMeT3Meta } from "/app/pages/rates-old.vue?macro=true";
import { default as gift_45cardUXJagRHSdWMeta } from "/app/pages/rates/gift-card.vue?macro=true";
import { default as indexkdtaGJYvmMMeta } from "/app/pages/rates/giftcard/buy/index.vue?macro=true";
import { default as indexCorETMi3Z4Meta } from "/app/pages/rates/giftcard/sell/index.vue?macro=true";
import { default as index9bxrMedbaeMeta } from "/app/pages/rates/index.vue?macro=true";
import { default as servicesF19YTkmrIFMeta } from "/app/pages/services.vue?macro=true";
import { default as terms_45and_45conditionszK6eZNsNEXMeta } from "/app/pages/terms-and-conditions.vue?macro=true";
export default [
  {
    name: about_45usWjawxlkv4gMeta?.name ?? "about-us",
    path: about_45usWjawxlkv4gMeta?.path ?? "/about-us",
    meta: about_45usWjawxlkv4gMeta || {},
    alias: about_45usWjawxlkv4gMeta?.alias || [],
    redirect: about_45usWjawxlkv4gMeta?.redirect || undefined,
    component: () => import("/app/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: blogsvd3tCE4tOqMeta?.name ?? "blogs",
    path: blogsvd3tCE4tOqMeta?.path ?? "/blogs",
    meta: blogsvd3tCE4tOqMeta || {},
    alias: blogsvd3tCE4tOqMeta?.alias || [],
    redirect: blogsvd3tCE4tOqMeta?.redirect || undefined,
    component: () => import("/app/pages/blogs.vue").then(m => m.default || m)
  },
  {
    name: animationsyTNd1827OMMeta?.name ?? "business-animations",
    path: animationsyTNd1827OMMeta?.path ?? "/business/animations",
    meta: animationsyTNd1827OMMeta || {},
    alias: animationsyTNd1827OMMeta?.alias || [],
    redirect: animationsyTNd1827OMMeta?.redirect || undefined,
    component: () => import("/app/pages/business/animations.ts").then(m => m.default || m)
  },
  {
    name: book_45a_45demockNhao9zG3Meta?.name ?? "business-book-a-demo",
    path: book_45a_45demockNhao9zG3Meta?.path ?? "/business/book-a-demo",
    meta: book_45a_45demockNhao9zG3Meta || {},
    alias: book_45a_45demockNhao9zG3Meta?.alias || [],
    redirect: book_45a_45demockNhao9zG3Meta?.redirect || undefined,
    component: () => import("/app/pages/business/book-a-demo.vue").then(m => m.default || m)
  },
  {
    name: indexqWs6x4EYp0Meta?.name ?? "business",
    path: indexqWs6x4EYp0Meta?.path ?? "/business",
    meta: indexqWs6x4EYp0Meta || {},
    alias: indexqWs6x4EYp0Meta?.alias || [],
    redirect: indexqWs6x4EYp0Meta?.redirect || undefined,
    component: () => import("/app/pages/business/index.vue").then(m => m.default || m)
  },
  {
    name: schemaD7mzE6zVGcMeta?.name ?? "business-schema",
    path: schemaD7mzE6zVGcMeta?.path ?? "/business/schema",
    meta: schemaD7mzE6zVGcMeta || {},
    alias: schemaD7mzE6zVGcMeta?.alias || [],
    redirect: schemaD7mzE6zVGcMeta?.redirect || undefined,
    component: () => import("/app/pages/business/schema.ts").then(m => m.default || m)
  },
  {
    name: utils9hUqlRtnqkMeta?.name ?? "business-utils",
    path: utils9hUqlRtnqkMeta?.path ?? "/business/utils",
    meta: utils9hUqlRtnqkMeta || {},
    alias: utils9hUqlRtnqkMeta?.alias || [],
    redirect: utils9hUqlRtnqkMeta?.redirect || undefined,
    component: () => import("/app/pages/business/utils.ts").then(m => m.default || m)
  },
  {
    name: contact_45uszp1HYnA7MsMeta?.name ?? "contact-us",
    path: contact_45uszp1HYnA7MsMeta?.path ?? "/contact-us",
    meta: contact_45uszp1HYnA7MsMeta || {},
    alias: contact_45uszp1HYnA7MsMeta?.alias || [],
    redirect: contact_45uszp1HYnA7MsMeta?.redirect || undefined,
    component: () => import("/app/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45noticegeuSyXysWNMeta?.name ?? "privacy-notice",
    path: privacy_45noticegeuSyXysWNMeta?.path ?? "/privacy-notice",
    meta: privacy_45noticegeuSyXysWNMeta || {},
    alias: privacy_45noticegeuSyXysWNMeta?.alias || [],
    redirect: privacy_45noticegeuSyXysWNMeta?.redirect || undefined,
    component: () => import("/app/pages/privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: rates_45oldCKSdZcMeT3Meta?.name ?? "rates-old",
    path: rates_45oldCKSdZcMeT3Meta?.path ?? "/rates-old",
    meta: rates_45oldCKSdZcMeT3Meta || {},
    alias: rates_45oldCKSdZcMeT3Meta?.alias || [],
    redirect: rates_45oldCKSdZcMeT3Meta?.redirect || undefined,
    component: () => import("/app/pages/rates-old.vue").then(m => m.default || m)
  },
  {
    name: gift_45cardUXJagRHSdWMeta?.name ?? "rates-gift-card",
    path: gift_45cardUXJagRHSdWMeta?.path ?? "/rates/gift-card",
    meta: gift_45cardUXJagRHSdWMeta || {},
    alias: gift_45cardUXJagRHSdWMeta?.alias || [],
    redirect: gift_45cardUXJagRHSdWMeta?.redirect || undefined,
    component: () => import("/app/pages/rates/gift-card.vue").then(m => m.default || m)
  },
  {
    name: indexkdtaGJYvmMMeta?.name ?? "rates-giftcard-buy",
    path: indexkdtaGJYvmMMeta?.path ?? "/rates/giftcard/buy",
    meta: indexkdtaGJYvmMMeta || {},
    alias: indexkdtaGJYvmMMeta?.alias || [],
    redirect: indexkdtaGJYvmMMeta?.redirect || undefined,
    component: () => import("/app/pages/rates/giftcard/buy/index.vue").then(m => m.default || m)
  },
  {
    name: indexCorETMi3Z4Meta?.name ?? "rates-giftcard-sell",
    path: indexCorETMi3Z4Meta?.path ?? "/rates/giftcard/sell",
    meta: indexCorETMi3Z4Meta || {},
    alias: indexCorETMi3Z4Meta?.alias || [],
    redirect: indexCorETMi3Z4Meta?.redirect || undefined,
    component: () => import("/app/pages/rates/giftcard/sell/index.vue").then(m => m.default || m)
  },
  {
    name: index9bxrMedbaeMeta?.name ?? "rates",
    path: index9bxrMedbaeMeta?.path ?? "/rates",
    meta: index9bxrMedbaeMeta || {},
    alias: index9bxrMedbaeMeta?.alias || [],
    redirect: index9bxrMedbaeMeta?.redirect || undefined,
    component: () => import("/app/pages/rates/index.vue").then(m => m.default || m)
  },
  {
    name: servicesF19YTkmrIFMeta?.name ?? "services",
    path: servicesF19YTkmrIFMeta?.path ?? "/services",
    meta: servicesF19YTkmrIFMeta || {},
    alias: servicesF19YTkmrIFMeta?.alias || [],
    redirect: servicesF19YTkmrIFMeta?.redirect || undefined,
    component: () => import("/app/pages/services.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionszK6eZNsNEXMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionszK6eZNsNEXMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionszK6eZNsNEXMeta || {},
    alias: terms_45and_45conditionszK6eZNsNEXMeta?.alias || [],
    redirect: terms_45and_45conditionszK6eZNsNEXMeta?.redirect || undefined,
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  }
]